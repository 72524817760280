@import "../../../styles/helpers";

.section {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 800px;
    padding: 80px 0;
    border-top: 1px solid $neutrals6;
    @include d {
        min-height: 576px; }
    @include m {
        display: block;
        min-height: auto;
        padding: 64px 0;
        text-align: center; }
    @include dark {
        border-color: $neutrals3; } }

.wrap {
    position: relative;
    z-index: 3;
    max-width: 480px;
    @include d {
        max-width: 420px; }
    @include m {
        max-width: 100%;
        margin-bottom: 40px; } }

.stage {
    margin-bottom: 12px;
    @include hairline-1;
    color: $neutrals4; }

.title {
    margin-bottom: 20px;
    @include m {
        font-size: 48px; } }

.text {
    margin-bottom: 40px;
    @include body-2;
    color: $neutrals4; }

.btns {
    display: flex;
    @include m {
        display: block; } }

.button {
    @include m {
        width: 100%; }
    &:not(:last-child) {
        margin-right: 16px;
        @include m {
            margin: 0 0 16px; } } }

.gallery {
    position: absolute;
    top: 50%;
    right: calc(50% - 560px);
    width: 684px;
    transform: translateY(-50%);
    pointer-events: none;
    @include x {
        right: calc(50% - 520px);
        width: 550px; }
    @include d {
        right: calc(50% - 440px);
        width: 470px; }
    @include m {
        position: static;
        transform: translateY(0); }
    @include s {
        width: auto;
        margin: 0 0 0 -22px; } }

.preview {
    &:first-child {
        position: relative;
        z-index: 2; }
    &:nth-child(2) {
        right: -11%;
        bottom: -3.5%;
        z-index: 3;
        width: 47%; }
    &:not(:first-child) {
        position: absolute; }
    img {
        width: 100%; } }
