@import "../../styles/helpers";

.actions {
    &.active {
        .button {
            background: $neutrals1;
            box-shadow: inset 0 0 0 2px $neutrals1;
            @include dark {
                background: $neutrals1;
                box-shadow: inset 0 0 0 2px $neutrals1; }
            svg {
                fill: $neutrals8; } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.body {
    position: absolute;
    right: 0;
    bottom: calc(100% + 12px);
    width: 208px;
    padding: 16px 16px 20px;
    border-radius: 12px;
    background: $neutrals2;
    box-shadow: inset 0 0 0 2px $neutrals2, 0 4px 24px rgba($neutrals1, .5);
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include dark {
        background: $neutrals2;
        box-shadow: inset 0 0 0 2px $neutrals2, 0 4px 24px rgba($neutrals1, .5); } }

.item {
    display: flex;
    align-items: center;
    padding: 16px 0 12px;
    @include button-2;
    cursor: pointer;
    color: $neutrals4;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        color: $pink;
        svg {
            fill: $pink; } }
    &:not(:last-child) {
        border-bottom: 1px solid $neutrals3;
        @include dark {
            border-color: $neutrals3; } } }
