@import "../../../../styles/helpers";

.add {
    position: relative;
    svg {
        transition: all .2s;
        &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            opacity: 0; } }
    &.active {
        svg {
            &:first-child {
                opacity: 0; }
            &:nth-child(2) {
                opacity: 1; } } } }
