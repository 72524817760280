@import "../../styles/helpers";

.loader,
.loaderWhite {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    font-size: 4px;
    animation: loader 1.1s infinite ease;
    text-indent: -9999em;
    transform: translateZ(0); }

.loader {
    animation: loader-white 1.1s infinite ease;
    @include dark {
        animation-name: loader-white; } }

.loaderWhite {
    animation-name: loader-white; }


@keyframes loader {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #777e90, 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.5), -1.8em -1.8em 0 0em rgba(119,126,144, 0.7); }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.7), 1.8em -1.8em 0 0em #777e90, 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.5); }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.5), 1.8em -1.8em 0 0em rgba(119,126,144, 0.7), 2.5em 0em 0 0em #777e90, 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2); }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.5), 2.5em 0em 0 0em rgba(119,126,144, 0.7), 1.75em 1.75em 0 0em #777e90, 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2); }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.5), 1.75em 1.75em 0 0em rgba(119,126,144, 0.7), 0em 2.5em 0 0em #777e90, -1.8em 1.8em 0 0em rgba(119,126,144, 0.2), -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2); }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.5), 0em 2.5em 0 0em rgba(119,126,144, 0.7), -1.8em 1.8em 0 0em #777e90, -2.6em 0em 0 0em rgba(119,126,144, 0.2), -1.8em -1.8em 0 0em rgba(119,126,144, 0.2); }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.5), -1.8em 1.8em 0 0em rgba(119,126,144, 0.7), -2.6em 0em 0 0em #777e90, -1.8em -1.8em 0 0em rgba(119,126,144, 0.2); }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(119,126,144, 0.2), 1.8em -1.8em 0 0em rgba(119,126,144, 0.2), 2.5em 0em 0 0em rgba(119,126,144, 0.2), 1.75em 1.75em 0 0em rgba(119,126,144, 0.2), 0em 2.5em 0 0em rgba(119,126,144, 0.2), -1.8em 1.8em 0 0em rgba(119,126,144, 0.5), -2.6em 0em 0 0em rgba(119,126,144, 0.7), -1.8em -1.8em 0 0em #777e90; } }

@keyframes loader-white {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7); }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5); }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff; } }
