@import "../../../styles/helpers";

.section {
    overflow: hidden;
    padding-bottom: 140px;
    @include x {
        padding-bottom: 112px; }
    @include m {
        padding-bottom: 64px; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: 54px;
    @include m {
        display: block;
        margin-bottom: 32px; } }

.stage {
    @include body-bold-1;
    color: $neutrals4; }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.field {
    .dropdown {
        min-width: 256px;
        @include m {
            min-width: 100%; } } }

.box {
    margin-right: auto;
    @include m {
        margin: 0 0 16px; }
    .dropdown {
        min-width: auto;
        margin-left: -24px;
        @include m {
            display: inline-block; } } }

.wrapper {
    margin: 0 -16px;
    @include m {
        margin: 0 -4px; } }

.item {
    padding: 24px;
    border-radius: 16px;
    background: $neutrals8;
    transition: box-shadow .2s;
    @include dark {
        background: $neutrals1; }
    &:hover {
        box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
        .avatar {
            width: 80px;
            @include d {
                width: 64px; } } } }

.head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.rating {
    display: flex;
    align-items: center;
    height: 24px;
    margin-right: auto;
    padding: 0 8px;
    border-radius: 12px;
    @include caption-bold-2;
    color: $neutrals8; }

.icon {
    margin-right: 4px;
    img {
        width: 16px; } }

.control {
    display: flex; }

.button {
    svg {
        fill: $neutrals5;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.body {
    text-align: center; }

.avatar {
    position: relative;
    width: 64px;
    margin: 0 auto 16px;
    transition: width .2s;
    & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; }
    &:before {
        content: "";
        display: block;
        padding-bottom: 100%; } }

.reward {
    position: absolute;
    right: -4px;
    bottom: -4px; }

.name {
    margin-bottom: 2px;
    font-weight: 500; }

.price {
    @include caption-2;
    color: $neutrals4;
    span {
        font-weight: 600;
        color: $neutrals2;
        @include dark {
            color: $neutrals6; } } }
